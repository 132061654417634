import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-870b89ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container-fluid"
};
const _hoisted_2 = {
  class: "locationList"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "locationInfoList"
};
const _hoisted_5 = {
  class: "address"
};
const _hoisted_6 = {
  class: "telbar"
};
const _hoisted_7 = {
  class: "icon"
};
const _hoisted_8 = {
  class: "contact"
};
const _hoisted_9 = {
  class: "mobile"
};
const _hoisted_10 = {
  class: "name"
};
const _hoisted_11 = {
  class: "map"
};
const _hoisted_12 = ["ak", "center", "zoom"];
import { ref, reactive, onMounted, watch } from "vue";
import "@/components/icons/";
import BMapStyleJson from "@/data/BMapStyle.json";
import offices from "@/data/Offices.json";
export default {
  __name: 'ContactInfo',
  setup(__props) {
    // ak: L0bNCCnIkxv8oxfjMNS30ltoMCaca0o8
    let locationLists = reactive(offices);
    let currentLocation = ref({});
    currentLocation.value = locationLists[0];
    function switchLocation(item) {
      currentLocation.value = item;
    }

    // 百度地图
    const bdMapAK = ref("L0bNCCnIkxv8oxfjMNS30ltoMCaca0o8");
    const center = ref({
      lng: 114.064362,
      lat: 22.609796
    });
    center.value = locationLists[0].position;
    const zoom = ref(17);
    onMounted(() => {
      const map = new window.BMap.Map("bdMap");
      console.log(BMapStyleJson);
      // map.enableScorllWheelZoom(false);
      map.centerAndZoom(new window.BMap.Point(center.value.lng, center.value.lat), zoom.value);
      map.setMapStyleV2({
        styleJson: BMapStyleJson
      });
      map.disableScrollWheelZoom();
      function addMarker(point) {
        var myIcon = new window.BMap.Icon("/assets/images/map_marker.png", new window.BMap.Size(58, 70), {
          anchor: new window.BMap.Size(29, 70)
        });
        // 创建标注对象并添加到地图
        var marker = new window.BMap.Marker(point, {
          icon: myIcon
        });
        marker.setAnimation(window.BMAP_ANIMATION_BOUNCE);
        map.addOverlay(marker);
      }
      locationLists.forEach(location => {
        addMarker(new window.BMap.Point(location.position.lng, location.position.lat));
      });
      watch(currentLocation, async newVal => {
        // console.log(newVal.value.)
        map.centerAndZoom(new window.BMap.Point(newVal.position.lng, newVal.position.lat), zoom.value);
      });
    });
    return (_ctx, _cache) => {
      const _component_tel = _resolveComponent("tel");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(locationLists), item => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["location", {
            current: item.id == _unref(currentLocation).id
          }]),
          key: item.id,
          onClick: $event => switchLocation(item)
        }, _toDisplayString(item.location), 11, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(locationLists), item => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["info", {
            active: item.id == _unref(currentLocation).id
          }]),
          key: item.id
        }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.address), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_tel)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.mobile), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.contact), 1)])])], 2);
      }), 128))]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
        id: "bdMap",
        ak: bdMapAK.value,
        center: center.value,
        zoom: zoom.value,
        onReady: _cache[0] || (_cache[0] = (...args) => _ctx.handler && _ctx.handler(...args))
      }, null, 40, _hoisted_12)])]);
    };
  }
};