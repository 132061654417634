/*
 * @Description:
 * @Author: FreeDoM_BaT
 * @Date: 2023-01-17 15:15:51
 * @LastEditTime: 2023-01-17 15:16:20
 */
import axios from "./Config";
import { Apis } from "./Apis";

export function getList(data) {
  return new Promise((resolve, reject) => {
    let params = {
      page: data.page || 1,
      pagesize: 6,
    };
    axios
      .get(Apis.jobs.list, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
